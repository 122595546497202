<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalAddEditDivision" :title="title" @shown="init">
      <div class="form-group">
        <label for="name">{{ $t('name') }}</label>
        <b-form-input v-model="division.name" name="name" id="name" v-validate="'required|max:25'" :state="validateState('name')" />
        <span class="error-inputs">{{ errors.first('name') }}</span>
      </div>
      <div class="form-group">
        <label for="status">{{ $t('status') }}</label>
        <select v-if="division.id" name="status" id="status" class="form-control" v-validate="'required'" :state="validateState('status')" v-model="division.state">
          <option value="POR INICIAR">{{ $t('toStart') }}</option>
          <option value="EN PROGRAMACION">{{ $t('programming') }}</option>
          <option value="EN CURSO">{{ $t('inProgress') }}</option>
          <option value="TERMINADO">{{ $t('finished') }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('status') }}</span>
      </div>
      <div class="form-group">
        <label for="type">{{ $t('type') }}</label>
        <select :disabled="division.id && division.state !== 'TERMINADO'" name="type" id="type" class="form-control" v-validate="'required'" :state="validateState('type')" v-model="division.competition_type">
          <option value="RONDAS + FASE FINAL">{{ $t('roundsFinalPhase') }}</option>
          <option value="TODOS CONTRA TODOS + FASE FINAL">{{ $t('allFinalPhase') }}</option>
          <option value="ELIMINACION DIRECTA ESPECIAL">{{ $t('manualDirectDisposal') }}</option>
          <option value="ELIMINACION DIRECTA">{{ $t('autoDirectDisposal') }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('type') }}</span>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="teamDowns">{{ $t('numberTeamsDown') }}</label>
          <b-form-input type="number" name="teamDowns" id="teamDowns" v-validate="'required|numeric'" :state="validateState('teamDowns')" v-model="division.teams_down" />
          <span class="error-inputs">{{ errors.first('teamDowns') }}</span>
        </div>
        <div class="form-group col-6">
          <label for="teamLiguilla">{{ $t('numberTeamsPassLiguilla') }}</label>
          <b-form-input type="number" v-validate="'required|numeric'" :state="validateState('teamLiguilla')" name="teamLiguilla" id="teamLiguilla" v-model="division.teams_playoffs" />
          <span class="error-inputs">{{ errors.first('teamLiguilla') }}</span>
        </div>
      </div>
      <div class="form-group" v-if="division.id">
        <label for="season">{{ $tc('season', 1) | toCapitalize }}</label>
        <b-form-input type="text" disabled name="season" id="season" v-model="division.season_name" />
        <span class="error-inputs">{{ errors.first('season') }}</span>
      </div>
      <div class="form-group" v-if="user.type === 'SUPER'">
        <b-form-checkbox v-model="division.is_premium" name="premium" switch>Premium</b-form-checkbox>
        <span class="error-inputs">{{ errors.first('premium') }}</span>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalAddEditDivision')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    division: {
      required: true,
      type: Object
    },
    tournament: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      title: ''
    }
  },
  computed: {
    ...mapGetters(['lang', 'console', 'user'])
  },
  methods: {
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            name: this.division.name,
            teams_down: this.division.teams_down,
            teams_playoffs: this.division.teams_playoffs,
            competition_type: this.division.competition_type
          }
          if (this.user.type === 'SUPER') {
            payload.is_premium = this.division.is_premium
          }
          if (!this.division.id) {
            const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournament.id}/divisions/store`
            this.$axios.post(path, payload).then(response => {
              this.$store.dispatch('SET_DIVISIONS_ADD', response.data.data)
              this.$root.$emit('bv::hide::modal', 'modalAddEditDivision')
            })
          } else {
            payload.state = this.division.state
            const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournament.id}/divisions/${this.division.id}/update`
            this.$axios.put(path, payload).then(response => {
              this.$store.dispatch('SET_DIVISION_EDIT', response.data.data)
              this.$root.$emit('bv::hide::modal', 'modalAddEditDivision')
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    init () {
      if (!this.division.id) {
        this.title = this.$t('create')
      } else {
        this.title = this.$t('edit')
      }
    }
  }
}
</script>
