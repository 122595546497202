<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{$t('divisions')}}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="divisions" :fields="columns" :filter="filter">
      <div slot="name" slot-scope="row">
        <router-link :to="{ name: 'DivisionBack', params: { lang: lang, console: console, divisionId: row.item.id } }">{{ row.item.name }}</router-link>
      </div>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-dropdown id="ddDivisionOptions" right noCaret>
            <div slot="button-content" class="px-2">
              <i class="fa fa-ellipsis-v"></i>
            </div>
            <b-dropdown-item @click="openModalEdit(row.item)">{{ $t('edit') }}</b-dropdown-item>
            <b-dropdown-item v-if="row.item.state !== 'EN CURSO'" @click.prevent="toSeasons(row.item)">{{ $tc('season', 2) | toCapitalize }}</b-dropdown-item>
            <b-dropdown-item @click.prevent="toTeams(row.item)">{{ $t('teams') | toCapitalize }}</b-dropdown-item>
            <b-dropdown-item @click="toPlayers(row.item)">{{ $t('players') }}</b-dropdown-item>
            <b-dropdown-item @click="openModalChangeImage(row.item)">{{ $t('changeImage') }}</b-dropdown-item>
            <b-dropdown-item @click="toDeliverCup(row.item)">{{ $t('deliverCup') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <modal-add-edit-division :division="division" :tournament="tournament" />
    <modal-change-image v-if="division" />
  </div>
</template>
<script>
import ModalAddEditDivision from './ModalAddEdit'
import ModalChangeImage from './ModalChangeImage'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalAddEditDivision,
    ModalChangeImage
  },
  data () {
    return {
      filter: '',
      division: {},
      administrator: {},
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: true },
        { key: 'actions', label: '' }
      ],
      tournament: {}
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'divisions'
    ])
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    openModalChangeImage (division) {
      this.$store.dispatch('SET_DIVISION', JSON.parse(JSON.stringify(division)))
      this.$root.$emit('bv::show::modal', 'modalChangeImageDivision')
    },
    toDeliverCup (division) {
      this.$store.dispatch('SET_DIVISION', JSON.parse(JSON.stringify(division)))
      this.$router.push({ name: 'DivisionBackDeliverCup', params: { lang: this.lang, console: this.console, tournamentId: this.tournament.id, divisionId: division.id } })
    },
    toPlayers (division) {
      this.$store.dispatch('SET_DIVISION', JSON.parse(JSON.stringify(division)))
      this.$router.push({ name: 'DivisionBackPlayers', params: { lang: this.lang, console: this.console, tournamentId: this.tournament.id, divisionId: division.id } })
    },
    toTeams (division) {
      this.$store.dispatch('SET_DIVISION', JSON.parse(JSON.stringify(division)))
      this.$router.push({ name: 'DivisionBackTeams', params: { lang: this.lang, console: this.console, tournamentId: this.tournament.id, divisionId: division.id } })
    },
    toSeasons (division) {
      this.$store.dispatch('SET_DIVISION', JSON.parse(JSON.stringify(division)))
      this.$router.push({ name: 'DivisionBackSeasons', params: { lang: this.lang, console: this.console, tournamentId: this.tournament.id, divisionId: division.id } })
    },
    openModalEdit (division) {
      this.division = JSON.parse(JSON.stringify(division))
      this.$root.$emit('bv::show::modal', 'modalAddEditDivision')
    },
    create () {
      this.division = {}
      this.$root.$emit('bv::show::modal', 'modalAddEditDivision')
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/divisions/all`
      this.$axios.get(path).then((response) => {
        this.tournament = response.data.tournament
        this.$store.dispatch('SET_TOURNAMENT', this.tournament)
        this.$store.dispatch('SET_DIVISIONS', response.data.divisions)
        document.title = `${this.tournament.name} - ${this.$t('divisions')}`
      })
    }
  }
}
</script>
